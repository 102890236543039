var collectRequests;

var activeBankId = false;

/**
 * Sign with BankID
 *
 * @param personalNumber
 * @param userVisibleData
 * @param firstName
 * @param lastName
 * @returns {boolean}
 */
function signWithBankId(personalNumber, userVisibleData, firstName, lastName) {


    if (activeBankId) {
        console.log('Warning! Double click to active sign BankID');
        return false;
    }

    activeBankId = true;

    $.ajax({
        url: '/api/bank-id/sign',
        data: {personalNumber: personalNumber, userVisibleData: userVisibleData},
        method: 'GET',
        success: function (result) {
            waitForSignatureBankId(result.orderRef, result.transactionId, personalNumber);
        },
        error: function (jqXHR, textStatus) {

            // console.log(jqXHR);
            // console.log(textStatus);

            console.log('BankID response: ' + jqXHR.responseJSON.error.message + ' - ' + jqXHR.responseJSON.error.internal_error_code );
            showBankIdErrorMessage(jqXHR.responseJSON.error.message, jqXHR.responseJSON.error.internal_error_code);
        }
    });

    return false;
}

function showBankIdErrorMessage(message, code){
    var html = '<div class="alert alert-danger">' + message + '<br><small>' + code + '</small></div>';
    $('.bank-id-instructions').html(html);
}

/**
 * Authorize with BankID
 *
 * @param personalNumber
 * @param firstName
 * @param lastName
 * @returns {boolean}
 */
function authWithBankId(personalNumber, firstName, lastName) {

    $.ajax({
        url: '/api/bank-id/auth',
        data: {personalNumber: personalNumber},
        method: 'GET',
        success: function (result) {
            // setBankIdLink(result.autoStartToken, result.orderRef, result.transactionId, personalNumber);
            waitForAuthBankId(result.orderRef, result.transactionId, personalNumber);
        },
        errors: function (result) {
            Rollbar.error("Error response from bank-id auth", {result: result, personalNumber: personalNumber});
        }
    });

    return false;
}



/**
 * Wait for signature with BankID
 *
 * @param orderRef
 * @param transactionId
 * @param personalNumber
 */
function waitForSignatureBankId(orderRef, transactionId, personalNumber) {
    $('#bankId-step-1').hide();
    $('#bankId-step-2').show();

    if (!transactionId) {
        Rollbar.error("waitForSignatureBankId without transcationID", {
            orderRef: orderRef,
            personalNumber: personalNumber
        });
    }

    var timesRun = 0;
    var timesToExpired = 180 / 3 - 3;
    collectRequests = setInterval(function () {

        ++timesRun;

        if (timesRun > timesToExpired) {
            $('#bankId-instructions').html('<div class="alert alert-danger">Tiden för ID har gått ut. <a href="">Ladda om sidan</a> för att påbörja en ny session</div>');
            window.clearInterval(collectRequests);
            console.log('Time expired');
            return false;
        }

        $.ajax({
            url: '/api/bank-id/collect-sign',
            data: {
                personalNumber: personalNumber,
                transactionId: transactionId,
                orderRef: orderRef
            },
            method: 'GET',
            success: function (result) {

                if (result.progressStatus == 'USER_SIGN') {

                    // The “BankID Säkerhetsapp” or “BankID Säkerhetsprogram” have received the signing request
                    $('#bankId-instructions').text('Skriv in din säkerhetskod i BankID-programmet för att signera');
                    $('#bankIdLink').fadeOut();

                } else if (result.progressStatus == 'STARTED') {

                    // One BankID client have been started, but there are no usable BankID on the client.
                    $('#bankId-instructions').text('Du har inget BankID som går att använda för den här underskriften på den här enheten. Om du har BankID på kort, sätt in det i läsaren. Om du inte har något BankID kontakta din Bank.');
                    $('#bankIdLink').fadeOut();

                } else if (result.progressStatus == 'COMPLETE') {

                    // The signing is completed
                    stopBankIdAttempt();

                    $('#bankId-step-2').hide();
                    $('#bankId-step-complete').show();

                    $('#transaction_id').val(result.certificate_id);
                    $('#bankIdFirstName').val(result.firstName);
                    $('#bankIdLastName').val(result.lastName);
                    $('#continue-message').text('Du skickas snart vidare till nästa steg');

                    setTimeout( function () {
                        $('#step-form').submit();
                    }, 500);
                }

            },
            error: function (result) {
                stopBankIdAttempt();

                var errorMessage = result.responseJSON.error.message;
                $('#bankId-instructions').html('<div class="alert alert-danger">' + errorMessage + '</div>');
            }
        });
    }, 3000);

    function stopBankIdAttempt(){
        window.clearInterval(collectRequests);
        $('#bankId-loading-icon').hide();
    }

}

/**
 * Wait for authentication with BankID
 *
 * @param orderRef
 * @param transactionId
 * @param personalNumber
 */
function waitForAuthBankId(orderRef, transactionId, personalNumber) {

    $('#bankId-step-1').hide();
    $('#bankId-step-2').show();

    var timesRun = 0;
    var timesToExpired = 180 / 3 - 3;
    collectRequests = setInterval(function () {

        ++timesRun;

        if (timesRun > timesToExpired) {
            $('#bankId-instructions').html('<span class="text-danger">Tiden för ID har gått ut. <a href="">Ladda om sidan</a> för att påbörja en ny session</span>');
            window.clearInterval(collectRequests);
            return false;
        }

        $.ajax({
            url: '/api/bank-id/collect-auth',
            data: {
                personalNumber: personalNumber,
                transactionId: transactionId,
                orderRef: orderRef
            },
            method: 'GET',
            success: function (result) {

                if (result.progressStatus == 'USER_SIGN') {
                    $('#bankId-instructions').text('Skriv in din säkerhetskod i BankID-programmet för att logga in');
                    $('#bankIdLink').fadeOut();
                } else if (result.progressStatus == 'COMPLETE') {

                    window.clearInterval(collectRequests);

                    $('#bankId-step-2').hide();
                    $('#bankId-step-complete').show();

                    $('#transaction_id').val(result.certificate_id);
                    $('#bankIdFirstName').val(result.firstName);
                    $('#bankIdLastName').val(result.lastName);
                    $('#continue-message').text('Du skickas snart vidare till nästa steg');

                    setTimeout( function () {
                        $('#step-form').submit();
                    }, 500);
                }

            },
            error: function (result) {

                Rollbar.error("Error response collect auth", {
                    result: result, personalNumber: personalNumber,
                    transactionId: transactionId,
                    orderRef: orderRef});

                $('#bankId-instructions').html('<div class="alert alert-danger">Ett tekniskt fel har uppstått. Vänligen försök igen! Om problemet kvarstår, vänligen kontakta oss</div>');
                window.clearInterval(collectRequests);
            }
        });
    }, 3000);

}

/**
 * Get link to open BankID
 *
 * @param autoStartToken
 * @param orderRef
 * @param transactionId
 * @param personalNumber
 * @returns {string}
 */
function getBankIdLink(autoStartToken, orderRef, transactionId, personalNumber) {
    var redirectUrl = window.location.href + '?redirectFromBankId=true&' + '&orderRef=' + orderRef +
        '&transactionId=' + transactionId + '&personalNumber=' + personalNumber;

    return 'bankid:///?autostarttoken=' + autoStartToken + '&redirect=' + encodeURIComponent(redirectUrl);
}

/**
 * Place a link to open BankID on the same divoce
 *
 * @param autoStartToken
 * @param orderRef
 * @param transactionId
 * @param personalNumber
 */
function setBankIdLink(autoStartToken, orderRef, transactionId, personalNumber) {
    $('#bankIdLink').attr('href', getBankIdLink(autoStartToken, orderRef, transactionId, personalNumber)).show();
}