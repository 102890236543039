/* On page load */
$(function () {

    /* Add and remove classed for parent container when selection a radio */
    var $radios = $('input:radio');
    $radios.change(function () {
        var $radios = $('input:radio[name=' + $(this).attr('name') + ']');
        $radios.parent().parent().removeClass('active');
        $(this).parent().parent().addClass('active');
    });

    /* Initiate bootstrap tooltip */
    $('[data-toggle="tooltip"]').tooltip();

    /* Initiate bootstrap popover */
    $('[data-toggle="popover"]').popover({container: 'body'});

});

/**
 * Short version of show an object
 *
 * @param object
 */
function show(object) {
    $(object).slideDown();
}

/**
 * Short version of hiding an object
 *
 * @param object
 */
function hide(object) {
    $(object).slideUp();
}
