/**
 * Resize the signature container
 */
function resizeSignatureContainer()
{
    signatureOb = $("#signature");
    parent = signatureOb.parent();
    parent.height(parent.width()/3);
}

function readDeviceOrientation()
{
    resizeSignatureContainer();

    /*
     switch (window.orientation) {
     case 0:

     $('#tips').show();
     break;

     case 180:

     $('#tips').show();
     break;

     case -90:

     $('#tips').hide();
     break;

     case 90:
     $('#tips').hide();
     break;
     } */
}

window.onorientationchange = readDeviceOrientation;

/**
 * Render the signature space from jSignature
 */
function initSignature()
{
    resizeSignatureContainer();

    signatureOb = $("#signature");
    signatureOb.jSignature({lineWidth:1, width: '100%',  height: '100%'});
}

/* Initiate jQuery */
$(function () {

    // Reset signature by click 'clear all' button
        $('#signature-reset').on('click', function () {
            $('#signature').jSignature('reset');
            $('#signature-data').val('');
        });

    // upload signature button click
        $(document).on("change", "#signature", function (e) {
            e.preventDefault();
            var datapair = $('#signature').jSignature("getData", "svgbase64");
            var data = 'data:' + datapair.join(',');
            $('#signature-data').val(data);
        });

});